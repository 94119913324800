@import "./styles/mixins.scss";
@import "./styles/palette.scss";
@import "./styles/typography.scss";

// Global style alterations

// Text-fields
.MuiFormControl-root {
  // Labels
  .MuiFormLabel-root {
    &.Mui-focused {
      color: $Primary50;
    }
    &.Mui-error {
      color: $LightError;
    }
  }

  // Input
  .MuiInputBase-root {
    border-radius: 12px;
    .MuiOutlinedInput-notchedOutline {
      border-color: $DarkOutline;
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $DarkOutline;
        box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85);
      }
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $InitioBlue;
      }
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $LightError;
      }
    }
  }
  // Helper text
  .MuiFormHelperText-root {
    @include body-small;
  }
}

//Snackbar
.MuiSnackbar-root {
  .MuiPaper-root {
    margin-top: 48px;
    border-radius: 8px;
    background-color: #313033;
    .MuiSnackbarContent-message {
      @include spaced-between-flexbox(center);
      align-items: center;
      gap: 12px;
      @include body-medium;
      color: #ffffff;
      .MuiButtonBase-root {
        @include text-only-button-dark;
      }
    }
  }
}

// Loading spinner
@include spinning-dots;

.MuiButtonBase-root {
  .dots-circle-spinner {
    margin-right: 8px;
  }
}

.loading-dialog {
  .MuiPaper-root {
    overflow: hidden;
    padding: 24px;
    opacity: 0.8;
    .custom-loading-spinner {
      $spinnerSize: 62px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
  }
}
