@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.ClientInputs {
  .client-review-block {
    margin: 16px 20px;
    h5 {
      @include label-large;
      text-decoration: underline;
    }
    .client-review-tiles {
      @include spaced-between-flexbox(flex-start, wrap);
      gap: 40px;
      .client-review-tile {
        width: 360px;
        @include spaced-between-flexbox(flex-start, nowrap, column);
        align-items: flex-start;
        p {
          margin-bottom: 4px;
          &:first-child {
            @include label-medium;
          }
          @include body-medium;
        }
        .MuiButtonBase-root {
          @include btn-to-link-text;
          padding: 0;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .review-loading-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .custom-loading-spinner {
      $spinnerSize: 62px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
  }
}
