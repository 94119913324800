@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.ClientIntakeForm {
  // Stepper Icon Styles
  &.success-container {
    margin: 0 auto;
    background: #fafafd;
    box-shadow: 0px 8px 16px rgba(0, 49, 93, 0.2);
    border-radius: 28px;
    padding: 40px 48px 30px;
    max-width: 880px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1,
    h2 {
      @include headline-large("Manrope");
      color: #49454f;
      margin-bottom: 24px;
    }
    a {
      margin-top: 24px;
    }
    .success-msg-container {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 32px 65px;
      background: #f2faf3;
      border-radius: 4px;
      max-width: 784px;
      margin-top: 24px;
      p {
        @include body-medium;
        color: $Success50;
        margin: 0;
      }
      .MuiSvgIcon-root {
        color: $Success60;
      }
    }
    .payment-page-link {
      margin-top: 12px;
      @include text-only-button(underline);
      color: $InitioBlue;
    }
  }

  // Stepper Icon Styles
  .start-btn {
    @include label-large;
    @include blue-contained-button;
    margin-top: 40px;
  }
  .bread-crumb {
    @include title-large;
    font-weight: 400;
    @include spaced-between-flexbox(flex-start);
    gap: 24px;
    align-items: center;
    margin: 24px 52px;
    .bread-crumb-btn {
      border: 0px;
      color: $Primary40;
      background-color: transparent;
      &:hover {
        opacity: 0.8;
      }
      .back-icon,
      .top-nav-title {
        margin-right: 12px;
      }
    }
  }
  .client-form-container {
    max-width: 880px;
    margin: 38px auto 0px;
    padding-bottom: 100px;
    .bottom-btn-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      .back-to-review {
        @include label-large;
        @include text-only-button;
        margin-right: 5px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .back-btn {
        @include label-large;
        @include outlined-button;
        margin-right: 10px;
      }
      .next-btn {
        @include label-large;
        @include blue-contained-button;
        border-radius: 100px;
      }
    }
    .loading-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 24px;
      z-index: 999;
      background: #fafafd;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      .custom-loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
    .client-form {
      max-width: 880px;
      position: relative;
      background-color: $LightBackground;
      box-shadow: 0px 8px 16px rgba(0, 49, 93, 0.2);
      border: 1px solid $Outline;
      border-radius: 24px;
      padding: 40px 40px;

      .MuiAutocomplete-root {
        background: #fafafd;
        width: 380px;
        margin-top: 24px;
        margin-bottom: 24px;
      }
      .MuiAutocomplete-groupLabel {
        background-color: $LightPrimaryContainer;
      }
      h4 {
        @include headline-large("Manrope");
        color: #49454f;
        margin-bottom: 24px;
      }

      p {
        @include body-medium;
        color: #49454f;
        margin-bottom: 24px;
      }

      .hello-avatar-image {
        position: absolute;
        left: 5%;
      }

      .MuiStepLabel-root {
        padding: 0;
        .MuiStepLabel-iconContainer {
          padding: 0;
          width: 24px;
          height: 24px;
          margin-left: 2px;
          margin-right: 16px;
          border-radius: 50%;
          @include spaced-between-flexbox(center);
          align-items: center;
        }
        &.completed {
          .MuiStepLabel-iconContainer {
            background-color: $InitioBlue;
            .MuiSvgIcon-root {
              fill: #ffffff;
              padding: 3px;
            }
          }
        }
        &.is-next,
        &.has-started {
          .MuiStepLabel-iconContainer {
            @include body-small;
            line-height: 0;
            letter-spacing: 0;
            color: $InitioBlue;
            border: 2px solid $InitioBlue;
          }
        }
        &.not-started {
          .MuiStepLabel-iconContainer {
            @include body-small;
            line-height: 0;
            letter-spacing: 0;
            color: #49454f;
            border: 2px solid #49454f;
          }
        }
        .MuiStepLabel-label {
          @include label-large;
          font-weight: 400;
        }
        &.is-next {
          .MuiStepLabel-label {
            font-weight: 600;
          }
        }
      }
      .individual-step {
        &:hover {
          cursor: pointer;
        }
        [data-testid="CreateOutlinedIcon"] {
          width: 24px;
          height: 24px;
          color: #49454f;
          margin-left: 8px;
          margin-top: -2px;
          border-radius: 50%;
          padding: 3px;
          &:hover {
            background-color: rgba(0, 97, 164, 0.1) !important;
          }
          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }
          &:active {
            background-color: rgba(0, 97, 164, 0.4) !important;
          }
        }
      }

      .individual-step.no-click-event {
        pointer-events: none !important;
      }

      .MuiStepConnector-root {
        margin: 4px auto 4px 13px;
        .MuiStepConnector-line {
          min-height: 12px;
          width: 2px;
          margin-left: 0;
          background: #49454f;
          border: 1px solid #49454f;
          border-radius: 10px;
        }
      }

      .extra-info-link {
        @include body-medium;
        pointer-events: all;
        display: flex;
        align-items: center;
        width: fit-content;
        color: $Primary40;
        font-weight: 400;
        font-style: italic;
        margin: 0;
        margin-left: 42px;
        margin-top: -4px;
        gap: 4px;
        .MuiSvgIcon-root {
          color: $Primary50;
        }
      }
    }
    .bottom-btn-container {
      .loading-spinner {
        margin-right: 8px;
        $spinnerSize: 16px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
      .back-to-review {
        @include text-only-button;
        margin-right: 5px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .back-btn {
        @include outlined-button;
        margin-right: 10px;
      }
      .next-btn {
        @include blue-contained-button;
        border-radius: 100px;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .ClientIntakeForm {
    .client-form-container {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
