@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.StatusChipWrapper {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiInputBase-root {
    padding: 6px !important;
  }

  .status-chip {
    height: 28px;
    border-radius: 4px !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;

    &.single {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
    }

    .MuiSelect-select {
      font-family: "Roboto", Arial, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      padding: 0 !important;
      padding-right: 20px !important;
    }

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
      margin-top: 4px;
    }

    &.Completed {
      color: $Success50;
      background-color: #ecf9f6;
      border: 1px solid $Success80;
      .MuiSvgIcon-root {
        color: $Success50;
      }
      .MuiSelect-select {
        padding-right: 16px !important;
      }
      .check-icon {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }

    &.Work-in-progress,
    &.Calculation-in-progress,
    &.Client-not-started-409A,
    &.Client-409A-in-progress,
    &.Sandbox-sent-to-client {
      color: $LightOnSurfaceVariant;
      background-color: $NeutralVariant95;
      border: 1px solid #c9c5ca;
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
      }
    }

    &.Review-report,
    &.Calculation-failed {
      color: $InitioOrange;
      background-color: $Error95;
      border: 1px solid $Error90;
      .MuiSvgIcon-root {
        color: $InitioOrange;
      }
    }

    &.Review-sandbox {
      color: $Primary50;
      background-color: $Primary95;
      border: 1px solid $Primary80;
      .MuiSvgIcon-root {
        color: $Primary50;
      }
    }
  }
}

.select-admin-status-dropdown {
  @include select-dropdown;
  .MuiButtonBase-root {
    @include body-medium;
  }
}
