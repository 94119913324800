@import "../../styles/palette.scss";
@import "../../styles/typography.scss";
@import "../../styles/mixins.scss";


.TransactionInfo {
  margin: 0 auto;
  width: 880px;
  .final-homepage-header {
    @include flexbox(flex-start, center);
    margin-top: 63px;
    margin-bottom: 41px;
    h4 {
      @include display-small("Manrope");
      margin-bottom: 0;
      .user-name {
        color: $Primary50;
      }
    }
    .MuiButtonBase-root {
      @include tonal-contained-button;
      margin-left: 16px;
    }
    .bread-crumb-btn {
      @include title-large;
      font-weight: 400;
      border: 0px;
      background-color: transparent;
      color: #0061A4;
      margin-right: 16px;
      margin-top: 4px;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      .back-icon {
        margin-top: -4px;
        margin-right: 6px;
        color: #0061A4;
      }
    }
  }
  &.loading-transactions-data {
    margin: 60px auto 0px;
    color: $Outline;
    display: block;
  }
  .content-wrapper {
    width: 880px;
    left: 200px;
    top: 201px;
    background: $Background1;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    border-radius: 24px;

    padding-left: 47px;
    padding-right: 47px;
    padding-bottom: 30px;
    padding-top: 32px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    p {
      @include body-large;
      padding-bottom: 16px;
    }
    .sandbox-report-review-wrapper {
      @include flexbox(center, center);
      margin: 0 auto;
      border-radius: 12px;
      height: 250px;
    }
  }
  .TransactionInfoBlock {
    .overlay {
      position: absolute;
      border-radius: 12px;
      height: 250px;
      width: 246px;
      &:hover {
        cursor: pointer;
        background: rgba(0, 72, 132, 0.08);
      }
    }
    .in-progress {
      background-color: #EDF1FA;
      padding: 4px 8px;
      height: 28px;
      font-family: "Roboto";
      font-style: normal;
      width: 100px;
      font-weight: 600;
      font-size: 12px;
      margin-top: 4px;
      text-align: center;
    }
    &:nth-child(2),
    &:nth-child(3) {
      margin-left: 24px;
    }
    border-radius: 12px;
    .title-background-image {
      height: 150px;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      &.report-homepage-thumb {
        background-image: url(../../images/409A_Report_Homepage_Thumbnail.svg);
      }
      &.sandbox-homepage-thumb {
        background-image: url(../../images/409A_Sandbox_Homepage_Thumbnail.svg);
      }
      &.submission-homepage-thumb {
        background-image: url(../../images/409A_SubmissionForm_Homepage_Thumbnail.svg);
      }
    }
    &.item {
      width: 246px;
      background-color: $LightBackground;
      color: #49454f;
      border: 1px solid #b9bdc3;
      filter: drop-shadow(2px 5px 4px rgba(0, 49, 93, 0.2));
      .transaction-content {
        padding-left: 16px;
        padding-right: 22px;
        padding-bottom: 21px;
        padding-top: 19px;
        min-height: 100px;

        flex-wrap: nowrap;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .left-side {
          .final-info {
            font-family: "Roboto", Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            display: block;
          }
          .sub-label {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
          }
          .title {
            font-family: "Roboto", Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .right-side {
          .vertical-ellipsis-icon-wrapper {
            $heightWidth: 30px;
            height: $heightWidth;
            width: $heightWidth;
            border-radius: calc($heightWidth / 2);
            border: 0px;
            background-color: transparent;
            flex-wrap: nowrap;
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              cursor: pointer;
              background-color: #e1eaf3;
            }
          }
        }
      }
    }
  }
}
